import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.css';
import { icons } from './assets/icons'

import { Provider } from 'react-redux'
import store from './store';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
//import '../node_modules/react-bootstrap/dist/react-bootstrap.min'
import "react-datepicker/dist/react-datepicker.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
import 'react-toastify/dist/ReactToastify.css';
import '../node_modules/@material-ui/styled-engine/index'; 
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
React.icons = icons
ReactDOM.render(
  <Provider store={store}>
    <App/>
    
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
