import React, { useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import LoaderBar from './reusable/LoaderBar';
import './scss/style.scss';
import jwt_decode from "jwt-decode";

// const loading = (
//   <div className="pt-3 text-center">
//      <img  src={loading} />
//   </div>
// )
//window.addEventListener("beforeunload", () => localStorage.clear());
// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./Login/Login'));
//const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const UnauthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    !localStorage.getItem('token')
      ? <Component {...props} />
      : <Redirect to='/' />
  )} />
);


const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    localStorage.getItem('token')
      ? <Component {...props} />
      : <Redirect to='/login' />
  )} />
);

function App(){
 
  
    return (
      <BrowserRouter>
        <React.Suspense fallback={<LoaderBar/>}>
          <Switch>
            <UnauthenticatedRoute exact path="/login" name="Login Page" component={Login} />
            {/* <UnauthenticatedRoute exact path="/404" name="Page 404" component={Page404} />
            <UnauthenticatedRoute exact path="/500" name="Page 500" component={Page500} /> */}
            <AuthenticatedRoute path="/" name="Home" component={TheLayout} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }


export default App;
