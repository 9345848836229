import React from 'react';
import Loader from "react-loader-spinner";
import loading from '../assets/icons/images/loading.gif'
function LoaderBar() {
    return (
        <div className='container text-center'>
            <img  src={loading} style={{height:'200px',width:"200px",marginTop:'50px'}} />
        </div>
    )
}

export default LoaderBar;
